import React, { useContext } from 'react'
import Button from '../../../components/Button/Button'
import { Flex, Relative, Text, Icon } from '../../../assets/styles/components'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import { TableAddress } from '../styles'
import Spinner from '../../../components/Loader/Spinner'
import { AppContext } from '../../../context/AppContext'

const TabAddress = ({ loading, data, changeState, deleteAddress }) => {
  const { update } = useContext(AppContext)
  return (
    <Relative padding="25px 20px">
      <Row>
        <Col md={12} style={{ overflowX: 'scroll' }}>
          <TableAddress>
            <thead>
              <tr>
                <th width="20%">Receiver</th>
                <th width="35%">Delivery Address</th>
                <th width="25%">Postal Code</th>
                <th width="20%"></th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan="4"
                    style={{ textAlign: 'center', paddingTop: 10 }}
                  >
                    <Spinner className="fa-lg" />
                  </td>
                </tr>
              ) : data.length == 0 ? (
                <tr>
                  <td
                    colSpan="4"
                    style={{ textAlign: 'center', paddingTop: 10 }}
                  >
                    No address yet
                  </td>
                </tr>
              ) : (
                data.map((g, i) => (
                  <tr key={i}>
                    <td>
                      <Text fontWeight="bold">{g.receiver_name || '-'}</Text>
                      <Text>{g.receiver_phonenumber || '-'}</Text>
                    </td>
                    <td>
                      <Text fontWeight="bold">{g.name}</Text>
                      <Text>{g.address}</Text>
                    </td>
                    <td>
                      <Text textTransform="capitalize">
                        {`${g.location.provinsi}, ${g.location.kabupaten}, ${g.location.kecamatan}, ${g.location.kelurahan}, ${g.location.kodepos}, Indonesia`}
                      </Text>
                    </td>
                    <td>
                      <Button
                        color="ghost-grey"
                        padding="3px 5px"
                        margin="0"
                        fontSize="13px"
                        margin="0 5px 5px"
                        onClick={() => {
                          changeState('modal', true)
                          changeState('modalType', 'edit')
                          changeState('editData', g)
                        }}
                      >
                        <Icon className="fa fa-pencil-square-o" /> change
                      </Button>
                      <Button
                        color="ghost-grey"
                        padding="3px 5px"
                        margin="0"
                        fontSize="13px"
                        margin="0 5px 5px"
                        onClick={() => {
                          update('confirmation', {
                            text: 'Delete this address?',
                            submitText: 'Delete',
                            submit: () => {
                              deleteAddress(g.customer_address_id)
                              update('confirmation', null)
                            },
                          })
                        }}
                      >
                        <Icon className="fa fa-trash" /> delete
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </TableAddress>
        </Col>
      </Row>
      <Flex justifyContent="center">
        <Button
          color="blue"
          padding="8px 20px"
          width="40%"
          margin="30px 0 0"
          mobileStyle={{ width: '100%' }}
          onClick={() => {
            changeState('modal', true)
            changeState('modalType', 'add')
          }}
        >
          + Add Address
        </Button>
      </Flex>
    </Relative>
  )
}

export default TabAddress
