import React, { Fragment } from 'react'
import { Wrapper } from './styles'
import { Image } from '../../assets/styles/components'

class Help extends React.Component {
  constructor(props) {
    super(props)
    
  }
  render() {
    return (
      <Fragment>
        <a href="https://api.whatsapp.com/send?phone=6282111206399&text=Untuk Memulai Chat Silahkan Isi Data Berikut :%0D%0ANama :%0D%0AEmail : &source=&data=&app_absent=" target="_blank">
            <Wrapper>
        
            <Image
                src={require('../../assets/images/wa_icon.png')}
                width="25px"
                height="25px"
                margin="0 10px 0 0"
            />
          Whatsapp
         
        </Wrapper> </a>
       
      </Fragment>
    )
  }
}

export default Help
