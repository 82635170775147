import React, { Fragment, useState, useEffect } from 'react'
import Modal from '../../../components/Modal/Modal'
import Button from '../../../components/Button/Button'
import InputOutlined from '../../../components/Input/InputOutlined'
import { Text, Absolute, Icon } from '../../../assets/styles/components'
import { Underline } from '../styles'
import ErrorText from '../../../components/Error/ErrorText'
import Spinner from '../../../components/Loader/Spinner'
import TextareaOutlined from '../../../components/Input/TextareaOutlined'
import LocationQuery from './LocationQuery'
import Maps from '../../../components/Maps/MapsAddress'

const ModalAddAddress = ({
  loading,
  error,
  data,
  open,
  type,
  close,
  center,
  changeState,
  add,
  edit,
}) => {
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [receivername, setReceivername] = useState('')
  const [receiverphone, setReceiverphone] = useState('')
  const [address, setAddress] = useState('')
  const [location, setLocation] = useState({})
  useEffect(() => {
    if (type == 'edit' && data) {
      setId(data.customer_address_id)
      setName(data.name)
      setReceivername(data.receiver_name)
      setReceiverphone(data.receiver_phonenumber)
      setAddress(data.address)
      setLocation(data.location)
    }
  }, [])
  return (
    <Fragment>
      <Modal open={open} width="40%" padding="2em 2.5em 1.5em">
        <Absolute top="7px" right="7px">
          <Icon
            className="fa fa-times-circle fa-lg"
            color="#999"
            cursor="pointer"
            onClick={close}
          />
        </Absolute>
        <Text fontWeight="bold" fontSize="20px">
          {`${type === 'add' ? 'Add' : 'Edit'} Address`}
        </Text>
        <Underline />
        <form
          onSubmit={e => {
            type == 'add'
              ? add(
                  e,
                  name,
                  receivername,
                  receiverphone,
                  address,
                  location.location_id,
                )
              : edit(
                  e,
                  id,
                  name,
                  receivername,
                  receiverphone,
                  address,
                  location.location_id,
                )
          }}
        >
          <InputOutlined
            label="Address Name"
            placeholder="ex: Rumah"
            value={name}
            onChange={e => setName(e.target.value)}
            margin="0 0 15px"
          />
          <InputOutlined
            label="Receiver Name"
            placeholder="ex: John Doe"
            value={receivername}
            onChange={e => setReceivername(e.target.value)}
            margin="0 0 15px"
          />
          <InputOutlined
            label="Receiver Phone Number"
            placeholder="ex: 081234567890"
            value={receiverphone}
            onChange={e => setReceiverphone(e.target.value)}
            margin="0 0 15px"
          />
          <LocationQuery location={location} setLocation={setLocation} />
          <Maps
              center={center}
              changeCenter={e => changeState('center', e)}
            />
          <TextareaOutlined
            label="Full Address"
            placeholder="ex: Jl. Perumahan Residence No. 10"
            value={address}
            onChange={e => setAddress(e.target.value)}
            margin="0 0 15px"
          />
          <ErrorText center margin="0 0 25px">
            {error}
          </ErrorText>
          <Button color="blue" padding="10px 20px" block disabled={loading}>
            {loading ? <Spinner /> : type === 'add' ? 'Add' : 'Edit'}
          </Button>
        </form>
      </Modal>
    </Fragment>
  )
}

export default ModalAddAddress
