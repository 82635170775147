import { baseURL } from '../../utils/apihost'
import { fetchGet, fetchPost } from '../../utils/format'

export default {
  async login(Email, Password) {
    let form = JSON.stringify({
      Email,
      Password,
    })
    let responseJSON = await fetchPost(
      baseURL + '/login',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async register(
    NamaDepan,
    NamaBelakang,
    Email,
    Password,
    Gender,
    PhoneNumber,
  ) {
    let form = JSON.stringify({
      NamaDepan,
      NamaBelakang,
      Email,
      Password,
      Gender,
      PhoneNumber,
    })
    let responseJSON = await fetchPost(
      baseURL + '/register',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async loginSocial(Media, UserId, Nama, Email, Avatar, UserToken) {
    let form = JSON.stringify({ Media, UserId, Nama, Email, Avatar, UserToken })
    let responseJSON = await fetchPost(
      baseURL + '/processSocialLogin',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async resendConfirmationEmail(Email) {
    let form = JSON.stringify({ Email })
    let responseJSON = await fetchPost(
      baseURL + '/resendConfirmationEmail',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async forgotPassword(Email) {
    let form = JSON.stringify({ Email })
    let responseJSON = await fetchPost(
      baseURL + '/forgotPassword',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async checkForgotPasswordToken(Email, Token) {
    let form = JSON.stringify({ Email, Token })
    let responseJSON = await fetchPost(
      baseURL + '/checkForgotPasswordToken',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async resetPassword(Email, Token, Password, Konfirmasi) {
    let form = JSON.stringify({ Email, Token, Password, Konfirmasi })
    let responseJSON = await fetchPost(
      baseURL + '/resetPassword',
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },

  async verificate(id) {
    let form = JSON.stringify({})
    let responseJSON = await fetchPost(
      baseURL + '/userverification/' + id,
      form,
      '',
      true,
      false,
    )
    return responseJSON
  },
}
