import React from 'react'
import Slider from 'react-slick'
import { Relative, Image, Text } from '../../../assets/styles/components'
import { BlogStyled, BlogFooter } from '../styles'
import ProductList from '../../Product/components/ProductList'
import { NextArrow, PrevArrow } from '../../../utils/helper'
import { Col } from '../../../components/Bootstrap/Bootstrap'
import { useMediaQuery } from 'react-responsive'

const settings = {
  dots: false,
  // infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

const ProductRow = ({ data, add }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  return (
    <Col md={{ span: 12 }} sm={{ span: 12 }}>
      <Relative margin="15px 0" className="product-group">
        <div style={{ textAlign: 'center' }}>
          <div
            style={{
              background: `url('${data.background}')`,
              display: 'inline-block',
              padding: '0 30px',
              margin: '0 0 15px',
              backgroundSize: '100%',
            }}
          >
            <Text
              isTitle="true"
              fontWeight="bold"
              fontSize="30px"
              textAlign="center"
            >
              {data.name}
            </Text>
          </div>
        </div>
        <Slider
          {...settings}
          infinite={isMobile ? data.detail.length > 2 : data.detail.length > 5}
        >
          {data.detail &&
            data.detail.map((g, i) => (
              <div key={i}>
                <div style={{ padding: '0 10px' }}>
                  <ProductList data={g.product} add={add} />
                </div>
              </div>
            ))}
        </Slider>
      </Relative>
    </Col>
  )
}

export default ProductRow
