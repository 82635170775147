import React, { Fragment } from 'react'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import {
  Text,
  Relative,
  Flex,
  FlexCell,
  Image,
} from '../../../assets/styles/components'
import InputOutlined from '../../../components/Input/InputOutlined'
import Spinner from '../../../components/Loader/Spinner'
import ErrorText from '../../../components/Error/ErrorText'
import Button from '../../../components/Button/Button'
import Select from '../../../components/Select/Select'
import { typeChecker } from '../../../utils/helper'
import { numberOnly } from '../../../utils/format'

const TabProfile = ({
  firstname,
  lastname,
  email,
  gender,
  genderlist,
  phonenumber,
  avatar,
  changeState,
  error,
  loading,
  fileInput,
  submit,
}) => {
  return (
    <Relative padding="25px 20px">
      <Row>
        <Col xs={12} md={3}>
          <Image
            src={
              typeChecker(avatar) === 'string'
                ? avatar
                : avatar
                ? URL.createObjectURL(avatar)
                : ''
            }
            width="80px"
            height="80px"
            margin="5px auto 10px"
            borderRadius="50%"
            display="block"
          />
          <Text
            textAlign="center"
            textDecoration="underline"
            fontSize="12px"
            cursor="pointer"
            onClick={() => fileInput.click()}
          >
            Choose photo
          </Text>
        </Col>
        <Col xs={12} md={9}>
          <Text fontWeight="bold" fontSize="16px" margin="0 0 10px">
            Profile
          </Text>

          <Flex justifyContent="space-between">
            <FlexCell flex={0.45}>
              <InputOutlined
                label="First Name"
                placeholder="ex: John"
                value={firstname}
                onChange={e => changeState('firstname', e.target.value)}
                margin="0 0 15px"
                padding="10px 12px"
              />
            </FlexCell>
            <FlexCell flex={0.45}>
              <InputOutlined
                label="Last Name"
                placeholder="ex: Doe"
                value={lastname}
                onChange={e => changeState('lastname', e.target.value)}
                margin="0 0 15px"
                padding="10px 12px"
              />
            </FlexCell>
          </Flex>
          <Select
            label="Gender"
            value={gender}
            option={genderlist}
            optionKey="name"
            onChange={changeState}
            stateKey="gender"
            margin="0 0 15px"
            padding="10px 12px"
          />
          <InputOutlined
            id="updateemail"
            label="Email"
            placeholder="ex: johndoe@gmail.com"
            value={email}
            onChange={e => changeState('email', e.target.value)}
            margin="0 0 15px"
            padding="10px 12px"
            disabled
          />
          <InputOutlined
            label="Phone Number"
            placeholder="ex: 081234567890"
            value={phonenumber}
            onChange={e => {
              let val = e.target.value
              if (val.length > 0) val = numberOnly(val)
              changeState('phonenumber', val)
            }}
            margin="0 0 45px"
            padding="10px 12px"
          />
        </Col>
      </Row>
      <ErrorText center margin="0 0 15px">
        {error}
      </ErrorText>
      <Flex justifyContent="center">
        <Button
          color="blue"
          padding="8px 20px"
          width="40%"
          margin="0 auto"
          disabled={loading}
          onClick={submit}
        >
          {loading ? <Spinner /> : 'Save'}
        </Button>
      </Flex>
    </Relative>
  )
}

export default TabProfile
