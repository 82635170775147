import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { black, blue, white } from '../../assets/styles/styles'

const formControl = css`
  display: block;
  width: 100%;
  height: 34px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: ${white};
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`

const LabelOutlinedStyled = styled.label`
  color: ${black};
  font-size: 14px;
  opacity: 0.8;
  font-weight: 600;
`

const InputOutlinedStyled = styled.input`
  ${formControl};
  outline: none;
  box-shadow: none;
  padding: ${props => props.padding};
  height: auto;
  background-color: ${props => (props.disabled ? '#ddd' : white)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'normal')};
  &:focus {
    border-color: ${blue};
  }
`

const TextareaOutlinedStyled = styled.textarea`
  ${formControl};
  outline: none;
  box-shadow: none;
  padding: ${props => props.padding};
  height: ${props => props.height || '100px'};
  &:focus {
    border-color: ${blue};
  }
`

const LabelUnderlinedStyled = styled.label`
  position: absolute;
  bottom: 2px;
  left: 0;
  color: #7f8c8d;
  font-size: 14px;
  font-weight: normal;
  cursor: text;
  transition: 0.2s all ease;
  &.focused {
    bottom: 26px;
    left: 0;
    font-size: 12px;
  }
`

const InputUnderlinedStyled = styled.input`
  ${formControl};
  border: 0;
  border-bottom: 2px solid #efefef;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  padding: 2px 0;
  padding-left: ${props => (props.leftAddon ? '17px' : '0')};
  padding-right: ${props => (props.rightAddon ? '17px' : '0')};
  transition: 0.3s all ease;
  background-color: transparent;
  margin-top: ${props => props.margin};
  &:focus {
    border-color: ${blue};
  }
`

const LeftAddon = styled.div`
  position: absolute;
  left: 0;
  bottom: 8px;
  color: #555;
`

const RightAddon = styled.div`
  position: absolute;
  right: 0;
  bottom: 8px;
  color: #555;
`

export {
  formControl,
  LabelOutlinedStyled,
  InputOutlinedStyled,
  TextareaOutlinedStyled,
  LabelUnderlinedStyled,
  InputUnderlinedStyled,
  LeftAddon,
  RightAddon,
}
