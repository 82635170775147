import React, { useState } from 'react'
import { CategoryWrapper, CategoryList } from '../styles'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import {
  Text,
  Relative,
  Absolute,
  Icon,
  Flex,
} from '../../../assets/styles/components'
import Checkbox from '../../../components/Checkbox/Checkbox'
import InputOutlined from '../../../components/Input/InputOutlined'
import Spinner from '../../../components/Loader/Spinner'

const ProductSearchBrand = ({
  data,
  filter,
  loading,
  errordata,
  checkBrand,
}) => {
  const [text, setText] = useState('')
  return (
    <Row margin="0 -15px 30px">
      <Col md={12}>
        <Text margin="0 0 10px" fontSize="18px" fontWeight="bold">
          Filter by brands
        </Text>
        <Relative>
          <InputOutlined
            value={text}
            onChange={e => {
              setText(e.target.value)
            }}
            placeholder="Search brand"
            margin="0 0 15px"
            padding="8px 12px 8px 40px"
            onKeyPress={e => {
              if (e.key === 'Enter') filter(text)
            }}
            className="search-brand"
            style={{ fontSize: 14 }}
          />
          <Absolute top="50%" left="12px" transform="translateY(-50%)">
            <Icon
              className="fa fa-search"
              style={{ fontSize: 15 }}
              color="#888"
            />
          </Absolute>
        </Relative>
      </Col>
      <CategoryWrapper>
        {loading ? (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        ) : data.length > 0 ? (
          data.map((g, i) => (
            <Col key={i} md={12} margin="0 0 5px">
              <Checkbox
                checked={g.checked}
                text={g.name}
                onChange={() => checkBrand(i)}
                margin="0 0 10px"
              />
            </Col>
          ))
        ) : (
          <Flex justifyContent="center">
            <Text>No brand found</Text>
          </Flex>
        )}
      </CategoryWrapper>
    </Row>
  )
}

export default ProductSearchBrand
