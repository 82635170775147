import React from 'react'
import { FormGroup } from '../../assets/styles/components'
import { LabelOutlinedStyled, InputOutlinedStyled } from './styles'

const InputOutlined = props => {
  const { label, id, margin } = props
  return (
    <FormGroup margin={margin}>
      {label ? (
        <LabelOutlinedStyled htmlFor={id || label}>{label}</LabelOutlinedStyled>
      ) : null}
      <InputOutlinedStyled
        {...props}
        id={id || label}
        padding={props.padding}
      />
    </FormGroup>
  )
}

InputOutlined.defaultProps = {
  id: '',
  type: 'text',
  label: '',
  margin: '0 0 30px',
  padding: '8px 12px',
}

export default InputOutlined
