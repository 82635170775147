import React, { Fragment } from 'react'
import { Relative, Image, Text } from '../../../assets/styles/components'
import { Col, Row } from '../../../components/Bootstrap/Bootstrap'
import { Link } from 'react-router-dom'

const ProductImageGroup = ({ data }) => {
  return (
    <Fragment>
      {data.row &&
        data.row.map((g, i) => (
          <Col md={12} key={i}>
            <Row>
              {g.column
                ? g.column.map((h, j) => (
                    <Col md={12 / g.column.length} key={j} padding="0">
                      <a href={h.link} target="_blank">
                        <Image
                          src={h.picture}
                          width="100%"
                          display="block"
                          margin="0 auto"
                          mobileStyle={`width: 100%`}
                        />
                      </a>
                    </Col>
                  ))
                : null}
            </Row>
          </Col>
        ))}
    </Fragment>
  )
}

export default ProductImageGroup
