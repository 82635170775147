import styled from '@emotion/styled'
import { blue, breakpoints } from '../../assets/styles/styles'
import { Container } from '../Bootstrap/Bootstrap'

const FooterContainer = styled(Container)`
  padding: 2em 7em;
  background-color: #fff2e9;

  @media (max-width: ${breakpoints[0]}) {
    padding: 15px 20px;
  }
  @media (min-width: ${breakpoints[0]}) and (max-width: ${breakpoints[2]}) {
    padding: 15px 20px;
  }
`

const FooterWrapper = styled.ul`
  padding: 0;
  margin: 0;
`

const FooterList = styled.li`
  list-style: none;
  margin-bottom: 3px;
  div,
  a {
    cursor: pointer;
    color: #999;
    font-weight: bold;
    font-size: 14px;
    &:hover {
      text-decoration: none;
      color: ${blue};
    }
  }
`

export { FooterContainer, FooterWrapper, FooterList }
