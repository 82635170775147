import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import {
  Flex,
  Text,
  BoxShadow,
  Separator,
  Relative,
  PageWrapper,
  FlexCell,
} from '../../assets/styles/components'
import InputOutlined from '../../components/Input/InputOutlined'
import Button from '../../components/Button/Button'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import { withContext, typeChecker } from '../../utils/helper'
import Select from '../../components/Select/Select'
import Footer from '../../components/Footer/Footer'
import ajax from './ajax'
import ErrorText from '../../components/Error/ErrorText'
import Spinner from '../../components/Loader/Spinner'
import { Tab } from './styles'
import TabProfile from './components/TabProfile'
import TabAddress from './components/TabAddress'
import ModalAddAddress from './components/ModalAddAddress'


class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      phonenumber: '',
      avatar: '',
      gender: { id: 1, name: 'Female', value: 'Wanita' },
      genderlist: [
        { id: 1, name: 'Female', value: 'Wanita' },
        { id: 2, name: 'Male', value: 'Laki-laki' },
      ],
      loading: false,
      error: '',
      modal: false,
      success: '',
      tablist: [
        {
          id: 1,
          name: 'Profile',
          value: 1,
        },
        {
          id: 2,
          name: 'Address',
          value: 2,
        },
      ],
      tab: { id: 1, name: 'Profile', value: 1 },
      address: [],
      center: { lat: -6.175161, lng: 106.827106 },
      loadingAddress: false,
      loadingModal: false,
      errorModal: '',
      editData: {},
    }
    this.submit = this.submit.bind(this)
    this.fetchAddress = this.fetchAddress.bind(this)
    this.addAddress = this.addAddress.bind(this)
    this.editAddress = this.editAddress.bind(this)
    this.deleteAddress = this.deleteAddress.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    this.setupData()
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchAddress()
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  setupData = () => {
    const { customer } = this.props.context.state
    const { name, last_name, gender, email, phone_number, avatar } = customer
    this.setState({
      firstname: name,
      lastname: last_name,
      email,
      gender: this.state.genderlist.find((g) => g.value === gender),
      phonenumber: phone_number,
      avatar,
    })
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  async fetchAddress() {
    this.setState({ loadingAddress: true, errordata: false })
    const { context } = this.props
    const { token } = context.state
    let res = await ajax.getAddress(token)
    if (!res.success) {
      this.setState({ loadingAddress: false, errordata: true })
      return false
    }
    let address = res.data
    this.setState({ address, loadingAddress: false })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  async addAddress(
    e,
    name,
    receiver_name,
    receiver_phone,
    address,
    locationid,
  ) {
    e.preventDefault()
    this.setState({ loadingModal: true, errorModal: '' })
    const { token } = this.props.context.state
    let res = await ajax.addAddress(
      name,
      receiver_name,
      receiver_phone,
      address,
      locationid,
      token,
    )
    if (!res.success) {
      this.setState({ loadingModal: false, errorModal: res.message })
      return false
    }

    this.setState({
      loadingModal: false,
      modal: false,
    })
    this.props.context.update('success', res.message)
    this.fetchAddress()
  }
  async editAddress(
    e,
    address_id,
    name,
    receiver_name,
    receiver_phone,
    address,
    locationid,
  ) {
    e.preventDefault()
    this.setState({ loadingModal: true, errorModal: '' })
    const { token } = this.props.context.state
    let res = await ajax.editAddress(
      address_id,
      name,
      receiver_name,
      receiver_phone,
      address,
      locationid,
      token,
    )
    if (!res.success) {
      this.setState({ loadingModal: false, errorModal: res.message })
      return false
    }

    this.setState({
      loadingModal: false,
      modal: false,
    })
    this.props.context.update('success', res.message)
    this.fetchAddress()
  }
  async deleteAddress(address_id) {
    this.props.context.update('showLoading', true)
    const { token } = this.props.context.state
    let res = await ajax.deleteAddress(address_id, token)
    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('success', res.message)
    this.fetchAddress()
  }
  async submit(e) {
    e.preventDefault()
    const { token } = this.props.context.state
    const {
      firstname,
      lastname,
      email,
      password,
      gender,
      phonenumber,
      avatar,
    } = this.state
    this.setState({ loading: true, error: '' })
    let ava = typeChecker(avatar) === 'string' ? '' : avatar
    let res = await ajax.updateProfile(
      firstname,
      lastname,
      email,
      password,
      gender ? gender.value : '',
      phonenumber,
      ava,
      token,
    )
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }

    let res2 = await ajax.getProfile(token)
    if (res2.success) {
      this.props.context.login(res2.data, token)
    }

    this.props.context.update('success', res.message)
    this.setupData()

    this.setState({
      loading: false,
    })

    this.fileInput.value = ''
  }
  render() {
    const {
      firstname,
      lastname,
      email,
      gender,
      genderlist,
      phonenumber,
      avatar,
      error,
      loading,
      tablist,
      tab,
      address,
      loadingAddress,
      modal,
      center,
      modalType,
      loadingModal,
      errorModal,
      editData,
    } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1.5em 0.15em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Container padding="0">
                <Row>
                  <Col md={{ span: 8, offset: 2 }}>
                    <BoxShadow padding="0" borderRadius="10px">
                      <Flex padding="12px 15px">
                        {tablist.map((g, i) => (
                          <Tab
                            key={i}
                            active={tab.value === g.value}
                            onClick={() => this.changeState('tab', g)}
                          >
                            {g.name}
                          </Tab>
                        ))}
                      </Flex>
                      <Separator />
                      {tab.value === 1 ? (
                        <TabProfile
                          firstname={firstname}
                          lastname={lastname}
                          email={email}
                          gender={gender}
                          genderlist={genderlist}
                          phonenumber={phonenumber}
                          avatar={avatar}
                          error={error}
                          loading={loading}
                          fileInput={this.fileInput}
                          changeState={this.changeState}
                          submit={this.submit}
                        />
                      ) : null}
                      {tab.value === 2 ? (
                        <TabAddress
                          data={address}
                          loading={loadingAddress}
                          changeState={this.changeState}
                          deleteAddress={this.deleteAddress}
                        />
                      ) : null}
                    </BoxShadow>
                  </Col>
                </Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>

        <input
          ref={(fileInput) => (this.fileInput = fileInput)}
          type="file"
          accept="image/gif, image/jpeg, image/jpg, image/png, image/bmp"
          onChange={(e) => this.changeState('avatar', e.target.files[0])}
          style={{ display: 'none' }}
        />
        {modal ? (
          <ModalAddAddress
            open={modal}
            type={modalType}
            data={editData}
            center={center}
            close={() => {
              this.changeState('modal', false)
              this.changeState('modalType', '')
            }}
            changeState={this.changeState}
            add={this.addAddress}
            edit={this.editAddress}
            loading={loadingModal}
            error={errorModal}
          />
        ) : null}
      </Fragment>
    )
  }
}

export default withContext(Profile)
