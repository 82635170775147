import React, { useContext } from 'react'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import { Text, Flex } from '../../../assets/styles/components'
import ProductList from './ProductList'
import Spinner from '../../../components/Loader/Spinner'
import { AppContext } from '../../../context/AppContext'
import { ProductListWrapper } from '../styles'

const ProductSearchList = ({
  data,
  loading,
  totalcount,
  category,
  add,
  brand,
  frombrand,
  titletext,
}) => {
  const { state } = useContext(AppContext)
  return (
    <Row>
      <Col md={12}>
        <Text margin="10px 0 15px">
          Showing <strong>{data.length}</strong>{' '}
          <strong>
            product(s) for{' '}
            {frombrand
              ? (brand && brand.name) || ''
              : titletext
              ? titletext
              : category.thirdCategory.name}
          </strong>{' '}
          (<strong>1 - 15</strong> from <strong>{totalcount}</strong>)
        </Text>
      </Col>

      {loading ? (
        <Col md={12} padding="10px">
          <Flex justifyContent="center">
            <Spinner className="fa-2x" />
          </Flex>
        </Col>
      ) : data.length > 0 ? (
        data.map((g, i) => (
          <ProductListWrapper key={i}>
            <ProductList data={g} add={add} left />
          </ProductListWrapper>
        ))
      ) : (
        <Col md={12} padding="10px">
          <Flex justifyContent="center">
            <Text>No product found</Text>
          </Flex>
        </Col>
      )}
    </Row>
  )
}

export default ProductSearchList
