import React, { Fragment, useContext } from 'react'
import {
  Text,
  Flex,
  Icon,
  Relative,
  Image,
  FlexCell,
} from '../../assets/styles/components'
import { Container, Row, Col } from '../Bootstrap/Bootstrap'
import { Link } from 'react-router-dom'
import { FooterContainer, FooterWrapper, FooterList } from './styles'
import { AppContext } from '../../context/AppContext'
import { withContext } from '../../utils/helper'
import ajax from '../../routes/Home/ajax'
import { black, orange } from '../../assets/styles/styles'
import ModalWebview from '../Loader/ModalWebview'
import { baseURL } from '../../utils/apihost'

const mapIcon = {
  Facebook: <Icon className="fa fa-facebook-square fa-3x" />,
  Instagram: require('../../assets/images/logo-instagram.png'),
  Twitter: require('../../assets/images/logo-twitter.png'),
  Tiktok: require('../../assets/images/logo-tiktok.png'),
  dandanonline: require('../../assets/images/logo-instagram.png'),
  TopBrand: require('../../assets/images/logo_topstore.png'),
  SuperAwards: require('../../assets/images/logo_superawards.png'),
}

const mapColor = {
  Facebook: '#3b5998',
  Instagram: black,
  dandanonline: black,
  Tiktok: black,
  Twitter: '#00acee',
}

const payment = [
  {
    image: require('../../assets/images/logo-bni.png'),
    height: '21px',
  },
  {
    image: require('../../assets/images/logo-bca.png'),
  },
  {
    image: require('../../assets/images/logo-mandiri.png'),
    height: '25px',
  },
  {
    image: require('../../assets/images/logo-visa.png'),
    height: '21px',
  },
  {
    image: require('../../assets/images/logo-mastercard.png'),
    height: '23px',
  },
  {
    image: require('../../assets/images/logo-gopay.png'),
    height: '21px',
  },
  {
    image: require('../../assets/images/logo-shopeepay.png'),
    height: '21px',
  }
]

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contact: [],
      socialmedia: [],
      courier: [],
      modalTerms: false,
    }
    this.fetchData = this.fetchData.bind(this)
  }
  componentDidMount() {
    this.fetchData()
  }

  async fetchData() {
    const { token, refetchFooter } = this.props.context.state
    if (!refetchFooter) return false
    this.setState({ loading: true, errordata: false })
    // let res = await ajax.getFooterContact(token)
    // if (!res.success) {
    //   this.setState({ loading: false, errordata: true })
    //   return false
    // }
    let res = await ajax.getFooter(token)
    // let res2 = { success: false }
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let socialmedia = res.data.social_media || []
    let courier = res.data.courier || []
    let onlineplatform = res.data.platform || []
    let categories = res.data.category || []
    socialmedia = socialmedia.map(g => {
      g.icon = mapIcon[g.name]
      g.color = mapColor[g.name]
      return g
    })
    this.setState({
      loading: false,
    })
    this.props.context.update('categories', categories)
    this.props.context.update('refetchCategories', false)
    this.props.context.update('refetchFooter', false)
    // this.props.context.update('contact', res.data)
    this.props.context.update('socialmedia', socialmedia)
    this.props.context.update('courier', courier)
    this.props.context.update('onlineplatform', onlineplatform)
    localStorage.setItem('categories', JSON.stringify(categories))
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  render() {
    const {
      contact,
      socialmedia,
      courier,
      onlineplatform,
    } = this.props.context.state
    return (
      <Relative backgroundColor={'#fff2e9'}>
        <FooterContainer>
          <Row>
            <Col md={2} xs={6} padding="0">
              <Relative
                padding="0 15px"
                mobileStyle={{ padding: '0 0 0 15px' }}
              >
                <Text
                  fontWeight="bold"
                  fontSize="26px"
                  margin="0"
                  isTitle="true"
                >
                  Sitelink
                </Text>
                <FooterWrapper>
                  <FooterList>
                    <Link to="/about">About Us</Link>
                  </FooterList>
                  <FooterList>
                    <div onClick={() => this.changeState('modalTerms', true)}>
                      Terms and Conditions
                    </div>
                  </FooterList>
                  <FooterList>
                    <Link to="/faq">FAQ</Link>
                  </FooterList>
                
                  <FooterList style={{ marginTop:"15px" }}>
                    <a href="https://www.kemendag.go.id/" target='_blank' rel="noreferrer"><Image
                        src={require('../../assets/images/logo-kemendag.png')}
                        height="45px"
                        objectFit="contain"
                        margin="20 0 0 0px"
                      /> </a>
                  </FooterList>
                </FooterWrapper>
              </Relative>
            </Col>
            <Col md={4} xs={6} padding="0">
              <Relative padding="0 15px">
                <Text
                  fontWeight="bold"
                  fontSize="26px"
                  margin="0"
                  isTitle="true"
                >
                  Courier
                </Text>

                <Row>
                  {courier.map((g, i) => (
                    <Col xs={6} md={4} key={i} padding="0 0 10px">
                      <a
                        href={g.link}
                        target="_blank"
                        style={{ color: g.color }}
                      >
                        <Image
                          src={g.logo}
                          width="100%"
                          height={g.name == 'JNE' ? '18px' : '23px'}
                          objectFit="contain"
                          mobileStyle={{ height: '20px' }}
                        />
                      </a>
                    </Col>
                  ))}
                </Row>

                <Text
                  fontWeight="bold"
                  fontSize="26px"
                  margin="0"
                  isTitle="true"
                >
                  Payment Option
                </Text>

                <Row>
                  {payment.map((g, i) => (
                    <Col xs={6} md={3} key={i} padding="0 0 10px">
                      <Image
                        src={g.image}
                        width="100%"
                        height={g.height || '25px'}
                        objectFit="contain"
                        mobileStyle={{ height: '20px' }}
                      />
                    </Col>
                  ))}
                  <Col xs={12} margin="5px 0 0">
                    <Text fontSize="13px" fontWeight="bold">
                      Secured by
                      <Image
                        src={require('../../assets/images/logo-midtrans.png')}
                        height="16px"
                        objectFit="contain"
                        margin="0 0 0 10px"
                      /> |
                      <Image
                       src={require('../../assets/images/logo-kredivo.png')}
                       height="20px"
                       objectFit="contain"
                       margin="0 0 0 4px"
                     />
                    </Text>
                  </Col>
                </Row>
              </Relative>
            </Col>
            <Col md={3} xs={6} padding="0">
              <Relative
                padding="0 15px"
                mobileStyle={{ padding: '0 0 0 15px' }}
              >
                <Text
                  fontWeight="bold"
                  fontSize="26px"
                  margin="0"
                  isTitle="true"
                >
                  Social Media
                </Text>
                <Flex margin="0 0 18px" flexWrap="wrap">
                  {socialmedia.map((g, i) => {
                    if(g.name=="TopBrand"){
                      return (
                        <FlexCell padding="0" key={i} flex={'0 0 42%'}>
                          <a href={g.link} target="_blank">
                          
                            <Image
                              src={g.icon}
                              
                              width="100%"
                              height="100%"
                              objectFit="contain"
                              mobileStyle={{ width: '100%' }}
                            />
                          </a>
                        </FlexCell>
                      )
                    }else if(g.name=="SuperAwards"){
                      return (
                        <FlexCell padding="0 2px 2px 0" key={i} flex={'0 0 37%'}>
                          <a href={g.link} target="_blank">
                          
                            <Image
                              src={g.icon}
                              
                              width="100%"
                              height="100%"
                              objectFit="contain"
                              mobileStyle={{ width: '100%' }}
                            />
                          </a>
                        </FlexCell>
                      )
                    }else{
                      return (
                        <FlexCell padding="0 15px 15px 0" key={i} flex={'0 0 30%'}>
                          <a href={g.link} target="_blank">
                          
                            <Image
                              src={g.icon}
                              
                              width="55%"
                              height="55%"
                              objectFit="contain"
                              mobileStyle={{ width: '100%' }}
                            />
                          </a>
                        </FlexCell>
                      )
                    }
                  })}
                </Flex>

                {/* {contact.map((g, i) => (
              <Fragment key={i}>
                <Text
                  fontWeight="bold"
                  fontSize="26px"
                  margin="0"
                  isTitle="true"
                >
                  {g.name}
                </Text>
                <FooterWrapper>
                  <FooterList>
                    <Link to="/about">{g.email}</Link>
                  </FooterList>
                  <FooterList>
                    <Link to="/contact">{g.phone}</Link>
                  </FooterList>
                </FooterWrapper>
              </Fragment>
            ))} */}
              </Relative>
            </Col>
            <Col md={3} xs={6} padding="0">
              <Relative
                padding="0 15px"
                mobileStyle={{ padding: '0 0 0 15px' }}
              >
                <Text
                  fontWeight="bold"
                  fontSize="26px"
                  margin="0"
                  isTitle="true"
                >
                  Online Platform
                </Text>
                <Flex margin="0 0 18px" flexWrap="wrap">
                  {onlineplatform.map((g, i) => (
                    <FlexCell padding="0 15px 15px 0" key={i} flex={'0 0 30%'}>
                      <a href={g.link} target="_blank">
                        <Image
                          src={g.picture}
                          width="60%"
                          height="60%"
                          objectFit="contain"
                          mobileStyle={{ width: '100%' }}
                        />
                      </a>
                    </FlexCell>
                  ))}
                </Flex>
              </Relative>
            </Col>
          </Row>
        </FooterContainer>
        <ModalWebview
          open={this.state.modalTerms}
          close={() => this.changeState('modalTerms', false)}
          url={baseURL + '/terms'}
        />
      </Relative>
    )
  }
}

export default withContext(Footer)
