import React, { Fragment } from 'react'
import Modal from '../../components/Modal/Modal'
import { withContext } from '../../utils/helper'
import Button from '../../components/Button/Button'
import InputOutlined from '../../components/Input/InputOutlined'
import { Text, Absolute, Icon } from '../../assets/styles/components'
import { Underline } from './styles'
import ajax from './ajax'
import ErrorText from '../../components/Error/ErrorText'
import Spinner from '../../components/Loader/Spinner'
import { blue } from '../../assets/styles/styles'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      otp: '',
      loading: false,
      error: '',
      success: '',
      password: '',
      confirmpassword: '',
      next: false,
    }
    this.submit = this.submit.bind(this)
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.context.state.showForgot &&
      !this.props.context.state.showForgot
    ) {
      this.setState({
        email: '',
        otp: '',
        loading: false,
        error: '',
        success: '',
        password: '',
        confirmpassword: '',
        next: false,
      })
    }
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  async submit(e) {
    e.preventDefault()
    const { email } = this.state
    this.setState({ loading: true, error: '', success: '' })
    let res = await ajax.forgotPassword(email)
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    this.setState({ loading: false, success: res.message, next: true })
  }
  async reset(e) {
    e.preventDefault()
    const { email, otp, password, confirmpassword } = this.state
    this.setState({ loading: true, error: '', success: '' })
    let res = await ajax.resetPassword(email, otp, password, confirmpassword)
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    this.setState({ loading: false, success: '' })
    this.props.context.update('showForgot', false)
    this.props.context.update('success', res.message)
  }
  render() {
    const { state, update } = this.props.context
    const {
      email,
      otp,
      password,
      confirmpassword,
      loading,
      error,
      success,
      next,
    } = this.state
    return (
      <Fragment>
        <Modal open={state.showForgot} width="30%">
          <Absolute top="7px" right="7px">
            <Icon
              className="fa fa-times-circle fa-lg"
              color="#999"
              cursor="pointer"
              onClick={() => update('showForgot', false)}
            />
          </Absolute>
          <Text fontWeight="bold" fontSize="20px">
            {next ? 'Reset Password' : 'Forgot Password'}
          </Text>
          <Underline />
          <form
            onSubmit={e => {
              next ? this.reset(e) : this.submit(e)
            }}
          >
            <InputOutlined
              id="forgotemail"
              label="Email"
              placeholder="ex: johndoe@gmail.com"
              value={email}
              onChange={e => this.changeState('email', e.target.value)}
              margin="0 0 20px"
            />
            {next ? (
              <Fragment>
                <InputOutlined
                  label="Token"
                  placeholder="Input token from email"
                  value={otp}
                  onChange={e => this.changeState('otp', e.target.value)}
                  margin="0 0 20px"
                />
                <InputOutlined
                  id="forgotpassword"
                  autocomplete="current-password"
                  type="password"
                  label="Password"
                  placeholder="Password must be at least 4 characters"
                  value={password}
                  onChange={e => this.changeState('password', e.target.value)}
                  margin="0 0 20px"
                />
                <InputOutlined
                  id="forgotconfpassword"
                  autocomplete="current-password"
                  type="password"
                  label="Password Confirmation"
                  placeholder="Input password once again"
                  value={confirmpassword}
                  onChange={e =>
                    this.changeState('confirmpassword', e.target.value)
                  }
                  margin="0 0 20px"
                />
              </Fragment>
            ) : null}
            <ErrorText center margin="0 0 15px">
              {error}
            </ErrorText>
            {success ? (
              <ErrorText center margin="0 0 15px" color={blue}>
                {success}
              </ErrorText>
            ) : null}
            <Button color="blue" padding="10px 20px" block disabled={loading}>
              {loading ? <Spinner /> : 'Confirm'}
            </Button>
          </form>
        </Modal>
      </Fragment>
    )
  }
}

export default withContext(ForgotPassword)
