import React, { Fragment } from 'react'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import {
  Image,
  Text,
  Relative,
  Separator,
  BoxShadow,
  Flex,
  Icon,
  InlineBlock,
  FlexCell,
  PageWrapper,
} from '../../assets/styles/components'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Copyright from '../../components/Copyright/Copyright'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import moment from 'moment'
import dummy from '../../utils/dummy'
import { withContext } from '../../utils/helper'
import ajax from './ajax'
import Spinner from '../../components/Loader/Spinner'
import Help from '../../components/Help/Help'
import { blue, orange } from '../../assets/styles/styles'
import PageTitle from '../../components/PageTitle/PageTitle'
import { host } from '../../utils/apihost'

class Brand extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      data: [],
      filter: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
    }
    this.fetchData = this.fetchData.bind(this)
    this.list = {}
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    this.fetchData()
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  async fetchData() {
    const { token } = this.props.context.state
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getBrandOnCategory('', '', token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let data = []
    this.state.filter.map(g => {
      let value = {
        name: g,
        data: [],
      }
      res.data.map(h => {
        let name = h.name.toUpperCase()
        if (g == name.charAt(0)) value.data.push(h)
        return h
      })

      data.push(value)
      return g
    })
    this.setState({
      data,
      loading: false,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  toggle = index => {
    let data = [...this.state.data]
    data[index].open = !data[index].open
    this.setState({ data })
  }
  scrollTo = alphabet => {
    this.list[alphabet].scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  render() {
    const { loading, errordata, data, filter } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative
              padding="1em 2em 3em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Breadcrumb text={`Home > Brand`} margin="0 0 10px" />

              {loading ? (
                <Flex justifyContent="center">
                  <Spinner className="fa-2x" />
                </Flex>
              ) : (
                <Container padding="0">
                  <Row margin="0 -15px 40px">
                    <Col md={12}>
                      <PageTitle text="Brand" />
                    </Col>
                  </Row>
                  <Row margin="0 -15px 45px">
                    <Col md={{ span: 8, offset: 2 }}>
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        {filter.map((g, i) => (
                          <FlexCell
                            key={i}
                            width={'5%'}
                            margin="0 0 10px"
                            mobileStyle={{ width: '10%' }}
                          >
                            <Text
                              cursor="pointer"
                              fontSize="20px"
                              fontWeight="bold"
                              textAlign="center"
                              hoverStyle={{ color: blue }}
                              onClick={() => this.scrollTo(g)}
                            >
                              {g}
                            </Text>
                          </FlexCell>
                        ))}
                      </Flex>
                    </Col>
                  </Row>
                  <div ref={ref => (this.wrapper = ref)}>
                    <Row>
                      <Col md={{ span: 8, offset: 2 }}>
                        {data.map((g, i) => (
                          <div key={i} ref={ref => (this.list[g.name] = ref)}>
                            <Row margin="0 -15px 30px" alignItems="center">
                              <Col md={2}>
                                <Text
                                  fontWeight="bold"
                                  fontSize="30px"
                                  color={orange}
                                >
                                  {g.name}
                                </Text>
                              </Col>
                              <Col md={10}>
                                <Row>
                                  {g.data.map((h, j) => (
                                    <Col key={j} md={4}>
                                      <a href={`/brand/${h.slug}`}>
                                        <Text margin="5px 0" cursor="pointer">
                                          {h.name}
                                        </Text>
                                      </a>
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </Container>
              )}
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(Brand)
