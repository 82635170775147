import React from 'react'
import Slider from 'react-slick'
import { Relative, Image, Text } from '../../../assets/styles/components'
import { BlogStyled, BlogFooter } from '../styles'
import ProductList from '../../Product/components/ProductList'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import { Link } from 'react-router-dom'

const Sponsored = ({ data }) => {
  return (
    <Relative>
      <a href={data.redirect_url} target="_blank">
        <Image
          className="rounded"
          src={data.link}
          width="100%"
          margin="0 auto"
          display="block"
          objectFit="contain"
          // height="185px"
          mobileStyle={{ height: 'auto' }}
        />
      </a>
    </Relative>
  )
}

export default Sponsored
