import styled from '@emotion/styled'
import { white, orange, breakpoints } from '../../assets/styles/styles'
import { Container } from '../Bootstrap/Bootstrap'

const HeaderTop = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
`

const HeaderStyled = styled(Container)`
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  padding: 1.2em 2em;
  background-color: ${orange};
  color: ${white};
  position: relative;
  z-index: 12;

  @media (max-width: ${breakpoints[0]}) {
    padding: 10px 20px;
  }
`

export { HeaderTop, HeaderStyled }
