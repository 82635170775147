import React, { Fragment } from 'react'
import {
  MenuHeader,
  MenuInline,
  MenuWrapper,
  MenuFirst,
  MenuFirstList,
  MenuSecond,
  MenuSecondList,
  MenuThird,
  MenuThirdList,
  MenuHeaderMobile,
} from './styles'
import { white, blue, black, breakpoints } from '../../assets/styles/styles'
import {
  Text,
  Absolute,
  Flex,
  Icon,
  Relative,
  Separator,
  Overlay,
} from '../../assets/styles/components'
import { Redirect } from 'react-router-dom'
import dummy from '../../utils/dummy'
import Masonry from 'react-masonry-component'
import ajax from '../../routes/Home/ajax'
import { withContext } from '../../utils/helper'
import MediaQuery from 'react-responsive'
import MenuMobile from './MenuMobile'

const masonryOptions = {
  transitionDuration: 0,
}

const imagesLoadedOptions = { background: '.my-bg-image-el' }

const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      open1: false,
      firstopen: false,
      firstopen1: false,
      secondopen: false,
      secondopen1: false,
      third: '',
      
      categories: [],
      catdigital:[],
      subcategory:[],
      subcategories: [],
      redirect: '',
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.fetchData()
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  async fetchData() {
    const { token, refetchMenu } = this.props.context.state
    if (!refetchMenu) return false
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getMenu(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let res1 = await ajax.getCategoryDigital(token)
  
    // let catdigital = res1.data
    // console.log(catdigital)
    


    let menu = [
      { name: 'Categories' },
      ...res.data,
      { name: 'Layanan Digital', link: 'https://beta.dandanku.com/productdigital' },
    ]

    this.setState({ loading: false,  catdigital: res1.data})
    this.props.context.update('menu', menu)
    this.props.context.update('refetchMenu', false)
  }
  handleClickOutside = (event) => {
    if (
      this.state.open &&
      this.wrapper &&
      !this.wrapper.contains(event.target)
    ) {
      this.setState({ open: false, third: '' })
    }
  }

  changeState = (key, value) => {
    this.setState({ [key]: value })
  }

  render() {
    const {
      subcategories,
      catdigital,
      subcategory,
      open,
      open1,
      firstopen,
      firstopen1,
      secondopen,
      secondopen1,
      third,
      redirect,
    } = this.state
    const { categories, categorydigital, menu } = this.props.context.state
    if (redirect) return <Redirect to={redirect} push />
    return (
      <Relative>
        <Overlay className={open ? 'open' : ''} />
        {/* desktop */}
        <MediaQuery minWidth={breakpoints[0]}>
          {open ? (
            <MenuWrapper
              onMouseLeave={() => {
                this.changeState('third', '')
                this.changeState('open', false)
              }}
              ref={(ref) => (this.wrapper = ref)}
            >
              <MenuSecond
                onMouseEnter={() => {
                  this.changeState('secondopen', true)
                }}
                onMouseLeave={() => {
                  this.changeState('secondopen', false)
                }}
              >
                {categories.map((g, i) => (
                  <MenuSecondList
                    key={i}
                    onMouseEnter={() => {
                      this.changeState('third', g)
                      this.changeState('subcategories', g.child)
                    }}
                    onClick={() => {
                      this.changeState('open', false)
                      // this.props.context.updateCategory({
                      //   mainCategory: g,
                      //   secondCategories: g.child,
                      //   secondCategory: g.child ? g.child[0] : {},
                      //   thirdCategories: g.child ? g.child[0].child : [],
                      //   thirdCategory: g.child ? g.child[0].child[0] : {},
                      // })
                      // if (this.props.location.pathname == '/product') {
                      //   window.scrollTo(0, 0)
                      //   return false
                      // }
                      // this.props.context.state.history.push('/product')
                    }}
                    className={g.name === third.name ? 'active' : ''}
                  >
                    <a href={`/subcategory/${g.slug}`}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text fontSize="17px" isTitle="true" fontWeight="bold">
                          {g.name}
                        </Text>
                        <Icon
                          className="fa fa-chevron-right"
                          style={{ fontSize: 12, color: white }}
                        />
                      </Flex>
                      <Separator />
                    </a>
                  </MenuSecondList>
                ))}
                <MenuSecondList
                  onClick={() => {
                    if (this.props.location.pathname == '/brand') {
                      this.changeState('open', false)
                      return false
                    }
                    this.props.context.state.history.push('/brand')
                  }}
                  onMouseEnter={() => {
                    this.changeState('third', '')
                    this.changeState('subcategories', [])
                  }}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text fontSize="17px" isTitle="true" fontWeight="bold">
                      Brand
                    </Text>
                    <Icon
                      className="fa fa-chevron-right"
                      style={{ fontSize: 12, color: white }}
                    />
                  </Flex>
                  <Separator />
                </MenuSecondList>
              </MenuSecond>
              {third ? (
                <MenuThird
                  onMouseEnter={() => {
                    this.changeState('secondopen', true)
                  }}
                >
                  <Text
                    margin="0 0 5px"
                    isTitle="true"
                    fontWeight="bold"
                    fontSize="26px"
                  >
                    {third.name}
                  </Text>
                  <Masonry
                    className={'masonry'} // default ''
                    elementType={'div'} // default 'div'
                    options={masonryOptions} // default {}
                    disableImagesLoaded={false} // default false
                    updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                    imagesLoadedOptions={imagesLoadedOptions} // default {}
                  >
                    {subcategories ? (
                      subcategories.map((g, i) => (
                        <MenuThirdList key={i}>
                          <a href={`/subcategory/${g.slug}?parent=${third.id}`}>
                            <Text
                              fontSize="19px"
                              // fontWeight="bold"
                              margin="0"
                              cursor="pointer"
                              hoverStyle={{ color: blue }}
                              isTitle="true"
                              onClick={() => {
                                // this.props.context.state.history.push({
                                //   pathname: `/subcategory/${third.id}?index=${i}`,
                                //   state: {
                                //     id: g.id,
                                //     indexes: [i],
                                //     data: subcategories,
                                //   },
                                // })
                                this.changeState('open', false)
                              }}
                            >
                              {g.name}
                            </Text>
                          </a>
                          {/* {g.child
                            ? g.child.map((h, j) => (
                                <Text
                                  key={j}
                                  fontSize="13px"
                                  margin="0 0 3px"
                                  cursor="pointer"
                                  hoverStyle={{ color: blue }}
                                  onClick={() => {
                                    this.props.context.updateCategory({
                                      mainCategory: third,
                                      secondCategories: subcategories,
                                      secondCategory: g,
                                      thirdCategories: g.child,
                                      thirdCategory: h,
                                    })
                                    if (
                                      this.props.location.pathname == '/product'
                                    ) {
                                      this.changeState('third', '')
                                      this.changeState('open', false)
                                      return false
                                    }

                                    this.props.context.state.history.push(
                                      '/product',
                                    )
                                  }}
                                >
                                  {h.name}
                                </Text>
                              ))
                            : null} */}
                        </MenuThirdList>
                      ))
                    ) : (
                      <Text fontSize="19px" margin="0 0 5px" isTitle="true">
                        No category found
                      </Text>
                    )}
                  </Masonry>
                </MenuThird>
              ) : null}
            </MenuWrapper>
          ) : null}
        </MediaQuery>

        <Overlay className={open1 ? 'open' : ''} />
        {/* desktop */}
        <MediaQuery minWidth={breakpoints[0]}>
          {open1 ? (
            <MenuWrapper
              onMouseLeave={() => {
                this.changeState('subcategory', [])
                this.changeState('open1', false)
              }}
              ref={(ref) => (this.wrapper = ref)}
            >
               <MenuSecond
                onMouseEnter={() => {
                  this.changeState('secondopen1', true)
                }}
                onMouseLeave={() => {
                  this.changeState('third', '')
                  this.changeState('secondopen1', false)
                }}
              >
                {catdigital.map((g, i) => (
                  <MenuSecondList
                    key={i}
                    onMouseEnter={() => {
                      this.changeState('subcategory', g.subcategory)
                    }}
                    onClick={() => {
                      this.changeState('open1', false)
                     
                    }}
                    
                  >
                    <a href={`/productdigital?parent=${g.slug}`}>
                      <Flex justifyContent="space-between" alignItems="center">
                        <Text fontSize="17px" isTitle="true" fontWeight="bold">
                          {capitalizeWords(g.name)}
                        </Text>
                        <Icon
                          className="fa fa-chevron-right"
                          style={{ fontSize: 12, color: white }}
                        />
                      </Flex>
                      <Separator />
                    </a>
                  </MenuSecondList>
                ))}
              
              </MenuSecond>
              {subcategory.length > 0 ? (
                <MenuThird
                  onMouseEnter={() => {
                    this.changeState('secondopen1', true)
                  }}
                >
                 
                  <Masonry
                    className={'masonry'} // default ''
                    elementType={'div'} // default 'div'
                    options={masonryOptions} // default {}
                    disableImagesLoaded={false} // default false
                    updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                    imagesLoadedOptions={imagesLoadedOptions} // default {}
                  >
                    {subcategory ? (
                      subcategory.map((g, i) => (
                        <MenuThirdList key={"1"}>
                          <a href={`/productdigital?child=${g.slug}`}>
                            <Text
                              fontSize="19px"
                              // fontWeight="bold"
                              margin="0"
                              cursor="pointer"
                              hoverStyle={{ color: blue }}
                              isTitle="true"
                              onClick={() => {
                            
                                this.changeState('open1', false)
                              }}
                            >
                              {capitalizeWords(g.name)}
                            </Text>
                          </a>
                        
                        </MenuThirdList>
                      ))
                    ) : (
                      <Text fontSize="19px" margin="0 0 5px" isTitle="true">
                        No category found
                      </Text>
                    )}
                  </Masonry>
                </MenuThird>
              ) : null}
            </MenuWrapper>
          ) : null}
        </MediaQuery>

        <MediaQuery minWidth={breakpoints[0]}>
          <MenuHeader>
            {menu.map((g, i) => (
              <MenuInline
                key={i}
                onMouseEnter={() => {
                  if (!g.link) {
                    this.changeState('open', true)
                    this.changeState('open1', false)
                    return false
                  }

                  if(g.name!='Layanan Digital') {
                    this.changeState('open1', false)
                    return false
                  }

                  if (g.name == 'Layanan Digital') {
                    this.changeState('open1', true)
                    this.changeState('open', false)
                    return false
                  }
                }}
                onClick={() => {
                  if (!g.link) {
                    this.changeState('open', !open)
                    return false
                  }

                  if (g.name == 'Layanan Digital') {
                    this.changeState('open1', !open1)
                    return false
                  }
                  if (this.props.location.pathname == g.link) return false
                  window.location.replace(g.link)
                  // this.props.history.push(g.url)
                }}
              >
                <Text
                  color={blue}
                  fontSize="20px"
                  isTitle="true"
                  fontWeight="bold"
                >
                  {g.name}
                </Text>
              </MenuInline>
            ))}
          </MenuHeader>
        </MediaQuery>

        <MenuMobile
          mobileMenu={this.props.mobileMenu}
          changeState={this.props.changeState}
          menu={this.state.menu}
          categories={this.state.categories}
          categorydigital={this.state.catdigital}
          location={this.props.location}
        />
      </Relative>
    )
  }
}

export default withContext(Menu)
