import React, { Fragment } from 'react'
import Modal from '../../components/Modal/Modal'
import { withContext } from '../../utils/helper'
import Button from '../../components/Button/Button'
import InputOutlined from '../../components/Input/InputOutlined'
import {
  Text,
  Absolute,
  Icon,
  Flex,
  Separator,
  FlexCell,
} from '../../assets/styles/components'
import { Underline } from './styles'
import ajax from './ajax'
import ErrorText from '../../components/Error/ErrorText'
import Spinner from '../../components/Loader/Spinner'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      loading: false,
      error: '',
    }
    this.submit = this.submit.bind(this)
    this.responseGoogle = this.responseGoogle.bind(this)
    this.responseFacebook = this.responseFacebook.bind(this)
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.context.state.showLogin &&
      !this.props.context.state.showLogin
    ) {
      this.setState({
        email: '',
        password: '',
        loading: false,
        error: '',
      })
    }
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  async submit(e) {
    e.preventDefault()
    const { email, password } = this.state
    this.setState({ loading: true, error: '' })
    let res = await ajax.login(email, password)
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    this.props.context.login(res.data.customer.Data, res.data.token)
    this.setState({ loading: false })
    this.props.context.update('showLogin', false)
  }
  async responseGoogle(response) {
    if (!response) return false
    const { profileObj } = response
    // this.setState({ loading: true, error: '' })
    this.setState({ error: '' })
    let res = await ajax.loginSocial(
      'Google',
      profileObj.googleId,
      profileObj.name,
      profileObj.email,
      profileObj.imageUrl,
      response.accessToken,
    )
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    this.props.context.login(res.data.customer.Data, res.data.token)
    this.setState({ loading: false })
    this.props.context.update('showLogin', false)
  }
  async responseFacebook(response) {
    if (!response) return false
    // this.setState({ loading: true, error: '' })
    this.setState({ error: '' })
    let res = await ajax.loginSocial(
      'Facebook',
      response.userID,
      response.name,
      response.email,
      response.picture ? response.picture.data.url : '',
      response.accessToken,
    )
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }
    this.props.context.login(res.data.customer.Data, res.data.token)
    this.setState({ loading: false })
    this.props.context.update('showLogin', false)
  }
  render() {
    const { state, update } = this.props.context
    const { email, password, loading, error } = this.state
    return (
      <Fragment>
        <Modal open={state.showLogin} width="30%" padding="2em 2.5em 1.5em">
          <Absolute top="7px" right="7px">
            <Icon
              className="fa fa-times-circle fa-lg"
              color="#999"
              cursor="pointer"
              onClick={() => update('showLogin', false)}
            />
          </Absolute>
          <Text fontWeight="bold" fontSize="20px">
            Login
          </Text>
          <Underline />
          <form onSubmit={this.submit}>
            <InputOutlined
              id="loginemail"
              label="Email"
              placeholder="ex: johndoe@gmail.com"
              value={email}
              onChange={e => this.changeState('email', e.target.value)}
              margin="0 0 15px"
            />
            <InputOutlined
              id="loginpassword"
              type="password"
              autocomplete="current-password"
              label="Password"
              placeholder="Password must be at least 4 characters"
              value={password}
              onChange={e => this.changeState('password', e.target.value)}
              margin="0 0 30px"
            />
            <ErrorText center margin="0 0 15px">
              {error}
            </ErrorText>
            <Button color="blue" padding="10px 20px" block disabled={loading}>
              {loading ? <Spinner /> : 'Login'}
            </Button>
            <Flex margin="17px 0 5px">
              <FlexCell flex={0.35}>
                <Separator />
              </FlexCell>
              <FlexCell flex={0.35}>
                <Text textAlign="center" fontSize="13px">
                  or
                </Text>
              </FlexCell>
              <FlexCell flex={0.35}>
                <Separator />
              </FlexCell>
            </Flex>
            <GoogleLogin
              clientId="746686254636-9r97c06ia46sshkserfju8398vklluib.apps.googleusercontent.com"
              buttonText="Google"
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogle}
              cookiePolicy={'single_host_origin'}
              className="btn-google"
              style={{ width: '100%', boxShadow: 'none' }}
            />
            <FacebookLogin
              appId="464283094254554"
              size="small"
              fields="name,email,picture"
              // onClick={componentClicked}
              callback={this.responseFacebook}
              cssClass="btn-facebook"
              icon={
                <Icon
                  className="fa fa-facebook-square fa-lg"
                  color="#3b5998"
                  margin="0 15px 0 0"
                />
              }
              textButton="Facebook"
            />
            <Text
              cursor="pointer"
              margin="30px 0 0"
              textDecoration="underline"
              fontSize="12px"
              textAlign="center"
              onClick={() => {
                update('showLogin', false)
                update('showForgot', true)
              }}
            >
              Forgot password?
            </Text>
          </form>
        </Modal>
      </Fragment>
    )
  }
}

export default withContext(Login)
