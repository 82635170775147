import React, { Component, Fragment, useContext } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { Global } from '@emotion/core'
import { withContext } from '../../utils/helper'
import Home from '../../routes/Home/Home'
import Register from '../../routes/Authentication/Register'
import Login from '../../routes/Authentication/Login'
import ModalLoading from '../Loader/ModalLoading'
import ProductSearch from '../../routes/Product/ProductSearch'
import ProductDetails from '../../routes/Product/ProductDetails'
import Cart from '../../routes/Cart/Cart'
import About from '../../routes/About/About'
import Order from '../../routes/Order/Order'
import Event from '../../routes/Event/Event'
import Store from '../../routes/Store/Store'
import Blog from '../../routes/Blog/Blog'
import ProductDigital from '../../routes/ProductDigital/ProductDigital'
import Mailer from '../../routes/Mailer/Mailer'
import FAQ from '../../routes/FAQ/FAQ'
import DandanLoader from '../Loader/DandanLoader'
import Profile from '../../routes/Profile/Profile'
import ModalSuccess from '../Loader/ModalSuccess'
import ForgotPassword from '../../routes/Authentication/ForgotPassword'
import { Wrapper } from './styles'
import ModalError from '../Loader/ModalError'
import ModalConfirmation from '../Loader/ModalConfirmation'
import ModalPayment from '../Loader/ModalPayment'
import ModalPaymentOrder from '../Loader/ModalPaymentOrder'
import BlogDetails from '../../routes/Blog/BlogDetails'
import ProductDigitalDetail from '../../routes/ProductDigital/ProductDigitalDetail'
import CartConfirmation from '../../routes/Cart/CartConfirmation'
import Notifications from '../../routes/Profile/Notifications'
import NotificationDetails from '../../routes/Profile/NotificationDetails'
import ModalCartAdded from '../../routes/Product/components/ModalCartAdded'
import SubCategory from '../../routes/Home/SubCategory'
import Verification from '../../routes/Authentication/Verification'
import Help from '../Help/Help'
import Wa from '../Whatsapp/Wa'
import { AppContext } from '../../context/AppContext'
import Brand from '../../routes/Product/Brand'
import BrandProduct from '../../routes/Product/BrandProduct'
import Sale from '../../routes/Product/Sale'
import ProductGateway from '../../routes/Product/ProductGateway'
import PaymentLink from '../../routes/PaymentLink/PaymentLink'

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  const { state } = useContext(AppContext)
  return (
    <Route
      {...rest}
      render={(props) => {
        return authenticated ? (
          <Wrapper promo={state.promoHeight}>
            <Component {...props} />
            <Login />
            <ForgotPassword />
            <ModalLoading />
            <DandanLoader />
            <ModalSuccess />
            <ModalError />
            <ModalPayment />
            <ModalPaymentOrder />
            <ModalConfirmation />
            <Help />
            <Wa />
          </Wrapper>
        ) : (
          <Redirect to="/" />
        )
      }}
    />
  )
}

const CustomRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(AppContext)
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Wrapper promo={state.promoHeight}>
            <Component {...props} />
            <Login />
            <ForgotPassword />
            <ModalLoading />
            <DandanLoader />
            <ModalSuccess />
            <ModalError />
            <ModalPayment />
            <ModalPaymentOrder />
            <ModalConfirmation />
            <ModalCartAdded />
            <Help />
            <Wa />
          </Wrapper>
        )
      }}
    />
  )
}

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { state } = this.props.context
    const { authenticated } = state
    return (
      <Route
        render={({ location }) => (
          <Switch location={location}>
            <CustomRoute exact path="/" component={Home} />
            <CustomRoute exact path="/register" component={Register} />
            <CustomRoute
              exact
              path="/verification/:id"
              component={Verification}
            />
            <CustomRoute exact path="/product" component={ProductSearch} />
            <CustomRoute exact path="/product/:id" component={ProductDetails} />
            <CustomRoute exact path="/sale" component={Sale} />
            <CustomRoute exact path="/brand" component={Brand} />
            <CustomRoute exact path="/brand/:id" component={BrandProduct} />

            <PrivateRoute
              exact
              authenticated={authenticated}
              path="/cart"
              component={Cart}
            />
            <PrivateRoute
              exact
              authenticated={authenticated}
              path="/cart/confirmation"
              component={CartConfirmation}
            />
            <PrivateRoute
              exact
              path="/order"
              authenticated={authenticated}
              component={Order}
            />
            <PrivateRoute
              exact
              path="/order/:id"
              authenticated={authenticated}
              component={Order}
            />
            <PrivateRoute
              exact
              authenticated={authenticated}
              path="/notification"
              component={Notifications}
            />
            <PrivateRoute
              exact
              authenticated={authenticated}
              path="/news/:id"
              component={NotificationDetails}
            />
            <PrivateRoute
              exact
              authenticated={authenticated}
              path="/profile"
              component={Profile}
            />

            <CustomRoute exact path="/about" component={About} />
            <CustomRoute exact path="/events" component={Event} />
            <CustomRoute exact path="/store" component={Store} />
            <CustomRoute exact path="/blog" component={Blog} />
            <CustomRoute exact path="/blog/:id" component={BlogDetails} />
            <CustomRoute
              exact
              path="/productdigital"
              component={ProductDigital}
            />
            <CustomRoute
              exact
              path="/productdigital/:id"
              component={ProductDigitalDetail}
            />
            <CustomRoute
              exact
              path="/subcategory/:id"
              component={ProductGateway}
            />
            <CustomRoute exact path="/mailer" component={Mailer} />
            <CustomRoute exact path="/faq" component={FAQ} />

            <CustomRoute exact path="/paymentlink/:id" component={PaymentLink} />
            {/* <Route exact path="/register" component={RegisterEmployee} /> */}
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        )}
      />
    )
  }
}

export default withRouter(withContext(Navigation))
