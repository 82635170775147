import React, { useContext } from 'react'
import Slider from 'react-slick'
import { Relative, Image, Text } from '../../../assets/styles/components'
import { BlogStyled, BlogFooter } from '../styles'
import ProductList from '../../Product/components/ProductList'
import { NextArrow, PrevArrow } from '../../../utils/helper'
import { Row, Col } from '../../../components/Bootstrap/Bootstrap'
import { useMediaQuery } from 'react-responsive'
import { AppContext } from '../../../context/AppContext'
import { dummyplaceholder } from '../../../utils/apihost'
import { Link } from 'react-router-dom'

const Category = ({ data, add }) => {
  const { state, update } = useContext(AppContext)
  const isMobile = useMediaQuery({ maxWidth: 768 })
  return (
    <Col md={{ span: 10, offset: 1 }}>
      <Relative margin="15px 0" className="product-group">
        <Text
          isTitle="true"
          fontWeight="bold"
          margin="0 0 15px"
          fontSize="30px"
          textAlign="center"
        >
          Categories
        </Text>
        <Row
          justifyContent={
            state.categories.length > 6 || isMobile ? 'flex-start' : 'center'
          }
        >
          {state.categories.map((g, i) => (
            <Col key={i} md={2} xs={6} margin="0 0 15px">
              <a href={`/subcategory/${g.slug}`} className="link">
                <Image
                  src={g.picture}
                  width="140px"
                  height="140px"
                  borderRadius="50%"
                  margin="0 auto 5px"
                  display="block"
                  mobileStyle={{ width: '100%', height: 'auto' }}
                  mediumStyle={{ width: '100%', height: 'auto' }}
                />
                <Text
                  textAlign="center"
                  fontWeight="bold"
                  fontSize="15px"
                  margin="10px 0 0"
                >
                  {g.name}
                </Text>
              </a>
            </Col>
          ))}
        </Row>
      </Relative>
    </Col>
  )
}

export default Category
