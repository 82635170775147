import React from 'react'
import moment from 'moment'
import '../../assets/css/calendar.css'
import { localeDay } from '../../utils/format'
import {
  Icon,
  Image,
  Text,
  Flex,
  FlexCell,
} from '../../assets/styles/components'
import Tooltip from 'rc-tooltip'
import { Row, Col } from '../Bootstrap/Bootstrap'
import { white, blue } from '../../assets/styles/styles'

export default class Calendar extends React.Component {
  weekdayshort = moment.weekdaysShort()

  state = {
    showYearTable: false,
    showMonthTable: false,
    showDateTable: true,
    dateObject: moment(),
    allmonths: moment.months(),
    selectedDay: null,
  }
  daysInMonth = () => {
    return this.state.dateObject.daysInMonth()
  }
  year = () => {
    return this.state.dateObject.format('Y')
  }
  currentDay = () => {
    return this.state.dateObject.format('D')
  }
  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject
    let firstDay = moment(dateObject)
      .startOf('month')
      .format('d') // Day of week 0...1..5...6
    return firstDay
  }
  month = () => {
    return this.state.dateObject.format('MMMM')
  }
  showMonth = (e, month) => {
    this.setState({
      showMonthTable: !this.state.showMonthTable,
      showDateTable: !this.state.showDateTable,
      // showYearTable: !this.state.showYearTable,
    })
  }
  setMonth = month => {
    let monthNo = this.state.allmonths.indexOf(month)
    let dateObject = Object.assign({}, this.state.dateObject)
    dateObject = moment(dateObject).set('month', monthNo)
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showDateTable: !this.state.showDateTable,
    })
  }
  MonthList = props => {
    let months = []
    props.data.map(data => {
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={e => {
            this.setMonth(data)
          }}
        >
          <span>{data}</span>
        </td>,
      )
    })
    let rows = []
    let cells = []

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
    })
    rows.push(cells)
    let monthlist = rows.map((d, i) => {
      return <tr>{d}</tr>
    })

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Select a Month</th>
          </tr>
        </thead>
        <tbody>{monthlist}</tbody>
      </table>
    )
  }
  showYearTable = e => {
    this.setState({
      showYearTable: !this.state.showYearTable,
      showDateTable: !this.state.showDateTable,
    })
  }

  onPrev = () => {
    let curr = ''
    if (this.state.showYearTable === true) {
      curr = 'year'
    } else {
      curr = 'month'
    }
    this.setState({
      dateObject: this.state.dateObject.subtract(1, curr),
    })
  }
  onNext = () => {
    let curr = ''
    if (this.state.showYearTable === true) {
      curr = 'year'
    } else {
      curr = 'month'
    }
    this.setState({
      dateObject: this.state.dateObject.add(1, curr),
    })
  }
  setYear = year => {
    // alert(year)
    let dateObject = Object.assign({}, this.state.dateObject)
    dateObject = moment(dateObject).set('year', year)
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showYearTable: !this.state.showYearTable,
    })
  }
  onYearChange = e => {
    this.setYear(e.target.value)
  }
  getDates(startDate, stopDate) {
    var dateArray = []
    var currentDate = moment(startDate)
    var stopDate = moment(stopDate)
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format('YYYY'))
      currentDate = moment(currentDate).add(1, 'year')
    }
    return dateArray
  }
  YearTable = props => {
    let months = []
    let nextten = moment()
      .set('year', props)
      .add('year', 12)
      .format('Y')

    let tenyear = this.getDates(props, nextten)

    tenyear.map(data => {
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={e => {
            this.setYear(data)
          }}
        >
          <span>{data}</span>
        </td>,
      )
    })
    let rows = []
    let cells = []

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
    })
    rows.push(cells)
    let yearlist = rows.map((d, i) => {
      return <tr>{d}</tr>
    })

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Pilih Tahun</th>
          </tr>
        </thead>
        <tbody>{yearlist}</tbody>
      </table>
    )
  }
  onDayClick = (e, d) => {
    this.setState(
      {
        selectedDay: d,
      },
      () => {
        console.log('SELECTED DAY: ', this.state.selectedDay)
      },
    )
  }
  render() {
    let weekdayshortname = this.weekdayshort.map(day => {
      return (
        <th key={day} style={{ color: blue, fontWeight: 'bold' }}>
          {day}
        </th>
      )
    })
    let blanks = []
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(<td className="calendar-day empty">{''}</td>)
    }
    let daysInMonth = []
    this.currentDay()
    for (let d = 1; d <= this.daysInMonth(); d++) {
      let currentDay = ''
      let today = ''
      let data = ''
      let current = moment(
        `${this.state.dateObject.format('YYYY')}-${this.state.dateObject.format(
          'MM',
        )}-${d}`,
        'YYYY-MM-DD',
      ).format('YYYY-MM-DD')

      data = this.props.data.find(
        g =>
          moment(g.start_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') ==
          current,
      )
      if (data) {
        currentDay = 'active'
      }

      if (current == moment().format('YYYY-MM-DD')) today = 'today'

      daysInMonth.push(
        <td key={d} className={`calendar-day ${currentDay} ${today}`}>
          {currentDay == 'active' ? (
            <Tooltip
              placement="top"
              trigger={['hover']}
              overlay={
                <div style={{ width: '200px' }}>
                  <Row margin="0">
                    <Col xs={5} padding="0">
                      <Image
                        className="rounded"
                        src={data.photo}
                        width="100%"
                      />
                    </Col>
                    <Col xs={7} padding="0 0 0 10px">
                      <Text
                        fontSize="13px"
                        margin="0 0 5px"
                        color={white}
                        style={{
                          height: 55,
                          overflow: 'hidden',
                          wordBreak: 'break-word',
                        }}
                      >
                        {data.name}
                      </Text>
                      <Text fontSize="12px" color={white} margin="0 0 5px">
                        {moment(data.start_time, 'YYYY-MM-DD HH:mm:ss').format(
                          'DD MMMM YYYY HH:mm',
                        )}
                      </Text>
                      <a href={data.link} target="_blank">
                        <Text color={white} textDecoration="underline">
                          More info
                        </Text>
                      </a>
                    </Col>
                  </Row>
                </div>
              }
            >
              <div
                onClick={e => {
                  this.onDayClick(e, d)
                }}
                className="day"
              >
                {d}
              </div>
            </Tooltip>
          ) : (
            <div
              onClick={e => {
                this.onDayClick(e, d)
              }}
              className="day"
            >
              {d}
            </div>
          )}
        </td>,
      )
    }
    var totalSlots = [...blanks, ...daysInMonth]
    let rows = []
    let cells = []

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row)
      } else {
        rows.push(cells)
        cells = []
        cells.push(row)
      }
      if (i === totalSlots.length - 1) {
        // let insertRow = cells.slice();
        rows.push(cells)
      }
    })

    let daysinmonth = rows.map((d, i) => {
      return <tr>{d}</tr>
    })

    return (
      <div className="tail-datetime-calendar">
        <div className="calendar-nav">
          <Icon
            onClick={e => {
              this.onPrev()
            }}
            className="fa fa-chevron-left"
            cursor="pointer"
            style={{ fontSize: 12 }}
          />
          <span style={{ fontSize: 32, letterSpacing: -2 }}>
            {this.month()} {this.year()}
          </span>
          <Icon
            onClick={e => {
              this.onNext()
            }}
            className="fa fa-chevron-right"
            cursor="pointer"
            style={{ fontSize: 12 }}
          />
        </div>

        {this.state.showDateTable && (
          <div className="calendar-date">
            <table className="calendar-day">
              <thead>
                <tr>{weekdayshortname}</tr>
              </thead>
              <tbody>{daysinmonth}</tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}
