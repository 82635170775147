import React from 'react'
import Slider from 'react-slick'
import { Relative, Image } from '../../../assets/styles/components'
import { BlogStyled, BlogFooter } from '../styles'
import { black } from '../../../assets/styles/styles'
import { NextArrow, PrevArrow } from '../../../utils/helper'
import { useMediaQuery } from 'react-responsive'

const settings = {
  dots: false,
  // infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

let objectFit = 'cover'
let height = '150px'
let maxHeight = '150px'

let tabletHeight = '120px'
let tabletMaxHeight = '120px'

const Banner = ({ data, isTablet }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  return (
    <Relative
      padding="0 30px"
      mobileStyle={{ marginBottom: 20 }}
      className="content-slider"
    >
      <Slider
        {...settings}
        infinite={isMobile ? data.length > 1 : data.length > 3}
        className="content-slider"
      >
        {data.map((g, i) => (
          <div key={i} style={{ width: '33%' }}>
            <BlogStyled>
              {/* <Image className="rounded"
                width="100%"
                // height="25vh"
                src={g.link}
                objectFit="cover"
              /> */}
              {g.type == 'Youtube Video' ? (
                <iframe
                  width="100%"
                  height={isTablet ? tabletHeight : height}
                  src={`https://www.youtube.com/embed/${g.youtube_video_id}`}
                  style={{
                    borderWidth: 0,
                    borderRadius: '10px',
                    maxHeight: isTablet ? tabletMaxHeight : maxHeight,
                  }}
                  allowfullscreen="allowfullscreen"
                  className="content-slider-item"
                ></iframe>
              ) : g.type == 'Picture' ? (
                <a href={g.link} target="_blank">
                  <Image
                    className="rounded content-slider-item"
                    src={g.resource}
                    style={{
                      width: '100%',
                      height: isTablet ? tabletHeight : height,
                      objectFit,
                      maxHeight: isTablet ? tabletMaxHeight : maxHeight,
                    }}
                  />
                </a>
              ) : (
                <video
                  src={g.resource}
                  style={{
                    width: '100%',
                    height: isTablet ? tabletHeight : height,
                    objectFit,
                    borderRadius: '10px',
                    maxHeight: isTablet ? tabletMaxHeight : maxHeight,
                  }}
                  controls
                  className="content-slider-item"
                />
              )}
              {/* <BlogFooter>{'Tampil Cantik Bersama Wardah'}</BlogFooter> */}
            </BlogStyled>
          </div>
        ))}
      </Slider>
    </Relative>
  )
}

export default Banner
