import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import { withContext } from '../../utils/helper'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import dummy from '../../utils/dummy'
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb'
import {
  Flex,
  Image,
  Text,
  Absolute,
  Relative,
  PageWrapper,
  FlexCell,
} from '../../assets/styles/components'
import ProductSearchList from './components/ProductSearchList'
import ProductSearchCategory from './components/ProductSearchCategory'
import ProductSearchBrand from './components/ProductSearchBrand'
import ajax from './ajax'
import Footer from '../../components/Footer/Footer'
import Pagination from './components/Pagination'
import { white } from '../../assets/styles/styles'

class BrandProduct extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errordata: false,
      category: [],
      brand: {},
      product: [],
      filteredbrand: [],
      activeCategory: '',
      totalcount: 0,
      page: 1,
      totalpage: 1,
    }
    this.fetchData = this.fetchData.bind(this)
    this.add = this.add.bind(this)
  }
  async componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    // setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
    await this.fetchBrand()
    await this.fetchData(1)
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  async fetchData(page = 1) {
    const { location, context } = this.props
    const { token } = context.state
    let id = location.pathname.split('-')
    id = id[id.length - 1]

    this.setState({
      loading: true,
      errordata: false,
    })
    let brand = [id]
    let res = await ajax.getProductList(page, '', brand, token)
    if (!res.success) {
      window.location.replace('/')
      this.setState({ loading: false, errordata: true })
      return false
    }
    let totalcount = res.data.product_count
    let totalpage = 1
    if (res.data.total_page) totalpage = res.data.total_page
    else {
      totalpage = parseInt(totalcount / 15)
      if (totalcount % 15 != 0) totalpage++
    }
    if (totalpage < 1) totalpage = 1
    this.setState({
      loading: false,
      product: res.data.product,
      totalcount,
      totalpage,
      page,
    })
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  async fetchBrand() {
    const { location, context } = this.props
    const { token } = context.state
    let id = location.state
      ? location.state.id
      : location.pathname.replace(/\D/g, '')
    this.setState({ loadingBrand: true, errordataBrand: false })
    let res = await ajax.getBrandOnCategory('', '', token)
    if (!res.success) {
      this.setState({ loadingBrand: false, errordataBrand: true })
      return false
    }
    let brand = res.data
    brand = brand.find(g => g.id == id)
    this.setState({
      brand,
      loadingBrand: false,
    })
  }
  async add(id, data) {
    const { token } = this.props.context.state
    const { qty } = this.state
    if (!token) {
      this.props.context.update('showLogin', true)
      return false
    }
    this.props.context.update('showLoading', true)

    let res = await ajax.addToCart(id, 1, token)

    if (!res.success) {
      this.props.context.update('showLoading', false)
      this.props.context.update('error', res.message)
      return false
    }
    this.props.context.update('showLoading', false)
    this.props.context.update('successCart', data)
    this.props.context.update('refetchCart', true)
  }
  render() {
    const {
      loading,
      errordata,
      product,
      totalcount,
      brand,
      page,
      totalpage,
    } = this.state
    const { location, context } = this.props
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />

        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Relative padding="1em 2em" mobileStyle={{ padding: '15px 20px' }}>
              <Breadcrumb
                text={`Home > ${brand ? brand.name : ''}`}
                margin="0"
              />
              <Container>
                <Row>
                  <Col md={2} padding="0">
                    <Relative
                      padding="0 30px 0 0"
                      mobileStyle={{ padding: 0 }}
                      backgroundColor={white}
                      // style={{
                      //   boxShadow: '0 1px 20px 0 rgba(155, 155, 155, 0.2)',
                      // }}
                    >
                      <Row margin="0 -15px 30px">
                        <Col md={12}>
                          <Text
                            margin="0"
                            isTitle="true"
                            fontSize="26px"
                            fontWeight="bold"
                          >
                            {brand ? brand.name : ''}
                          </Text>
                        </Col>
                      </Row>
                    </Relative>
                  </Col>
                  <Col md={10} padding="0">
                    <Relative padding="0 0 0 15px" mobileStyle={{ padding: 0 }}>
                      <ProductSearchList
                        data={product}
                        loading={loading}
                        errordata={errordata}
                        totalcount={totalcount}
                        add={this.add}
                        brand={brand}
                        frombrand={true}
                      />

                      {loading ? null : (
                        <Pagination
                          totalcount={totalcount}
                          totalpage={totalpage}
                          fetch={this.fetchData}
                          page={page}
                        />
                      )}
                    </Relative>
                  </Col>
                </Row>
              </Container>
            </Relative>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
      </Fragment>
    )
  }
}

export default withContext(BrandProduct)
