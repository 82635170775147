import React, { Fragment } from 'react'
import {
  ActionWrapper,
  HistoryList,
  BrandList,
  ProductList,
  Input,
} from './styles'
import {
  Text,
  Absolute,
  Flex,
  FlexCell,
  Icon,
  Image,
  Relative,
} from '../../assets/styles/components'
import dummy from '../../utils/dummy'
import InputOutlined from '../Input/InputOutlined'
import { white, black } from '../../assets/styles/styles'
import ajax from '../../routes/Product/ajax'
import Spinner from '../Loader/Spinner'
import { withContext } from '../../utils/helper'
import { host } from '../../utils/apihost'

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      loading: false,
      errordata: false,
      data: [],
      searched: false,
      focused: false,
      searchtext: '',
      historykeyword: [],
      historyproduct: [],
      brand: [],
      product: [],
      timeout: 0,
    }
    this.fetchData = this.fetchData.bind(this)
    this.search = this.search.bind(this)
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.fetchData()
  }
  componentDidUpdate(prevProps) {
    if (
      !prevProps.context.state.refetchSearchHistory &&
      this.props.context.state.refetchSearchHistory
    ) {
      this.fetchData()
      this.props.context.update('refetchSearchHistory', false)
    }
  }
  async fetchData() {
    const { token, refetchSearchHistory } = this.props.context.state
    if (!refetchSearchHistory || !token) return false
    this.setState({ loading: true, errordata: false })
    let res = await ajax.getSearchHistory(token)
    if (!res.success) {
      this.setState({ loading: false, errordata: true })
      return false
    }
    let historykeyword = res.data.reverse().slice(0, 6)
    this.setState({
      // historykeyword: res.data,
      // historyproduct: res.product,
      loading: false,
    })
    this.props.context.update('historykeyword', historykeyword)
    this.props.context.update('refetchSearchHistory', false)
  }
  async search(searchtext = this.state.searchtext) {
    if (this.state.loading) return false
    this.setState({ loading: true, errordata: false })
    const { token } = this.props.context.state
    let res = await ajax.search(searchtext, token || '')
    if (!res.success) {
      this.setState({ loading: false, errordata: true, brand: [], product: [] })
      return false
    }
    this.setState(
      {
        brand: res.data.brand,
        product: res.data.product,
        searched: true,
        loading: false,
        searchtext,
      },
      () => {
        let historykeyword = this.props.context.state.historykeyword
        historykeyword.unshift({
          id: -1,
          query: searchtext,
        })
        historykeyword = historykeyword.slice(0, 10)
        this.props.context.update('historykeyword', historykeyword)
      },
    )
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  //test baru
  handleKeyPress(event){
      const { value } = this.state;
      const { href } = window.location;
      window.location.href = `${href}product/holly-sabun-hijau-200g-353`;
    
  }
  handleClickOutside = (event) => {
    if (
      this.state.focused &&
      this.wrapper &&
      !this.wrapper.contains(event.target) &&
      this.inputWrapper &&
      !this.inputWrapper.contains(event.target)
    ) {
      this.setState({ focused: false, searched: false })
    }
  }
  toggle = () => {
    this.setState({ open: !this.state.open })
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  changeText = (searchtext) => {
    if (this.state.timeout) clearTimeout(this.state.timeout)
    this.setState({
      searchtext,
      timeout: setTimeout(() => {
        if (searchtext.length > 1) this.search(searchtext)
      }, 1000),
    })
  }
  render() {
    const {
      loading,
      errordata,
      data,
      open,
      focused,
      searched,
      searchtext,
      historyproduct,
      brand,
      product,
    } = this.state
    const { historykeyword } = this.props.context.state
    return (
      <Relative>
        <Relative ref={(ref) => (this.inputWrapper = ref)}>
          <Input
            value={searchtext}
            onChange={(e) => this.changeText(e.target.value)}
            placeholder="Search..."
            onFocus={() => this.changeState('focused', true)}
            // onKeyPress={(e) => {
            //   if (e.key === 'Enter') {
            //     clearTimeout(this.state.timeout)
            //     this.search()
            //   }
            // }}
            focused={focused}
            // onBlur={() => this.changeState('focused', false)}
          />
          <Absolute top="50%" left="12px" transform="translateY(-50%)">
            <Icon className="fa fa-search fa-lg" color={black} />
          </Absolute>
          <Absolute
            top="50%"
            right="22%"
            transform="translateY(-50%)"
            mobileStyle={{ right: '10px' }}
          >
            <Icon
              className="fa fa-times-circle fa-lg"
              style={{ display: focused ? 'block' : 'none' }}
              color={'#909090'}
              cursor="pointer"
              onClick={() => {
                this.setState({
                  focused: false,
                  searched: false,
                  searchtext: '',
                })
              }}
            />
          </Absolute>
        </Relative>
        {focused ? (
          <ActionWrapper ref={(ref) => (this.wrapper = ref)}>
            {loading ? (
              <Flex justifyContent="center">
                <Spinner />
              </Flex>
            ) : (
              <Fragment>
                {searched ? (
                  <Fragment>
                    <Text fontWeight="bold" margin="0 0 5px">
                      Merek
                    </Text>
                    <Relative padding="0 10px 10px">
                      {brand.length > 0 ? (
                        brand.map((g, i) => (
                          <BrandList
                            key={i}
                            href={`/brand/${g.slug}`}
                            onClick={() => {
                              this.setState({
                                focused: false,
                                searched: false,
                              })
                              // if (
                              //   this.props.match.path == '/brand/product/:id'
                              // ) {
                              //   window.scrollTo(0, 0)

                              //   window.location.replace(
                              //     `/brand/product/${g.id}`,
                              //   )
                              //   return false
                              // }
                            }}
                          >
                            <Image
                              src={g.icon}
                              width="80px"
                              height="30px"
                              objectFit="contain"
                            />
                            <Text
                              margin="0 0 0 10px"
                              fontWeight="bold"
                              fontSize="13px"
                            >
                              {g.name}
                            </Text>
                          </BrandList>
                        ))
                      ) : (
                        <Text margin="0 10px 5px">
                          {`No brand with keyword "${searchtext}"`}
                        </Text>
                      )}
                    </Relative>
                    <Text fontWeight="bold" margin="0 0 5px">
                      Product
                    </Text>
                    <Relative padding="0 10px 10px">
                      {product.length > 0 ? (
                        product.map((g, i) => (
                          <ProductList
                            href={`/product/${g.slug}`}
                            key={i}
                            onClick={() => {
                              this.setState({
                                focused: false,
                                searched: false,
                              })
                              // if (this.props.location.pathname == '/product') {
                              //   window.scrollTo(0, 0)

                              //   this.props.context.state.history.push({
                              //     pathname: `/product/${g.id}`,
                              //     state: { id: g.id, name: g.name },
                              //   })

                              //   return false
                              // }
                            }}
                          >
                            {g.name}
                            {g.brand ? ' - ' + g.brand.name : ''}
                          </ProductList>
                        ))
                      ) : (
                        <Text margin="0 10px 5px">
                          {`No product with keyword "${searchtext}"`}
                        </Text>
                      )}
                    </Relative>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Text fontWeight="bold" margin="0 0 5px">
                      Search History
                    </Text>
                    {historykeyword.length > 0 ? (
                      historykeyword.map((g, i) => (
                        <HistoryList
                          key={i}
                          onClick={() => this.search(g.query)}
                        >
                          {g.query}
                        </HistoryList>
                      ))
                    ) : (
                      <Text margin="0 0 5px">No search yet</Text>
                    )}
                    {/* <Text fontWeight="bold" margin="0 0 5px">
                      Barang yang terakhir diView
                    </Text>
                    <Flex>
                      {historyproduct.map((g, i) => (
                        <Image
                          key={i}
                          src={g.image}
                          width="50px"
                          margin="5px"
                          cursor="pointer"
                        />
                      ))}
                    </Flex> */}
                  </Fragment>
                )}
              </Fragment>
            )}
          </ActionWrapper>
        ) : null}
      </Relative>
    )
  }
}

export default withContext(Search)
