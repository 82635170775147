import React, { Fragment } from 'react'
import Header from '../../components/Header/Header'
import Copyright from '../../components/Copyright/Copyright'
import {
  Flex,
  Text,
  PageWrapper,
  FlexCell,
} from '../../assets/styles/components'
import InputOutlined from '../../components/Input/InputOutlined'
import Button from '../../components/Button/Button'
import { Container, Row, Col } from '../../components/Bootstrap/Bootstrap'
import { withContext } from '../../utils/helper'
import Select from '../../components/Select/Select'
import Footer from '../../components/Footer/Footer'
import ajax from './ajax'
import ErrorText from '../../components/Error/ErrorText'
import Spinner from '../../components/Loader/Spinner'
import ModalSuccess from './components/ModalSuccess'
import { numberOnly } from '../../utils/format'

class Register extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmpassword: '',
      gender: { id: 1, name: 'Female', value: 'Wanita' },
      genderlist: [
        { id: 1, name: 'Female', value: 'Wanita' },
        { id: 2, name: 'Male', value: 'Laki-laki' },
      ],
      phonenumber: '',
      loading: false,
      error: '',
      modal: false,
      success: '',
    }
    this.submit = this.submit.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    this.props.context.update('history', this.props.history)
    setTimeout(() => this.props.context.update('showDandanLoader', false), 1000)
  }
  componentWillUnmount() {
    this.props.context.update('showDandanLoader', true)
  }
  changeState = (key, value) => {
    this.setState({ [key]: value })
  }
  async submit(e) {
    e.preventDefault()
    const {
      firstname,
      lastname,
      email,
      password,
      confirmpassword,
      gender,
      phonenumber,
    } = this.state
    if (password !== confirmpassword) {
      this.setState({
        error: 'Password and password confirmation must be the same',
      })
      return false
    }
    this.setState({ loading: true, error: '' })
    let res = await ajax.register(
      firstname,
      lastname,
      email,
      password,
      gender.value,
      phonenumber,
    )
    if (!res.success) {
      this.setState({ loading: false, error: res.message })
      return false
    }

    this.setState({
      loading: false,
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmpassword: '',
      gender: this.state.genderlist[0],
      phonenumber: '',
      modal: true,
      success: res.message,
    })
  }
  render() {
    const {
      firstname,
      lastname,
      email,
      password,
      confirmpassword,
      gender,
      genderlist,
      phonenumber,
      error,
      loading,
      modal,
      success,
    } = this.state
    return (
      <Fragment>
        <Header match={this.props.match} location={this.props.location} />
        <PageWrapper promo={this.props.context.state.promo.content}>
          <FlexCell>
            <Flex
              justifyContent="center"
              padding="1.5em 0.15em 2em"
              mobileStyle={{ padding: '15px 20px' }}
            >
              <Container>
                <Row>
                  <Col md={{ span: 4, offset: 4 }}>
                    <Text
                      fontWeight="bold"
                      fontSize="20px"
                      textAlign="center"
                      margin="0 0 10px"
                    >
                      Register New Account
                    </Text>
                    <Text
                      fontSize="13px"
                      fontWeight="500"
                      textAlign="center"
                      margin="0 0 20px"
                    >
                      One step more to get interesting offers
                    </Text>
                    <form onSubmit={this.submit}>
                      <InputOutlined
                        label="First Name"
                        placeholder="ex: John"
                        value={firstname}
                        onChange={e =>
                          this.changeState('firstname', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                      />
                      <InputOutlined
                        label="Last Name"
                        placeholder="ex: Doe"
                        value={lastname}
                        onChange={e =>
                          this.changeState('lastname', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                      />
                      <InputOutlined
                        label="Phone Number"
                        placeholder="ex: 081234567890"
                        value={phonenumber}
                        onChange={e => {
                          let val = e.target.value
                          if (val.length > 0) val = numberOnly(val)
                          this.changeState('phonenumber', val)
                        }}
                        margin="0 0 15px"
                        padding="10px 12px"
                      />
                      <InputOutlined
                        id="regemail"
                        label="Email"
                        placeholder="ex: johndoe@gmail.com"
                        value={email}
                        onChange={e =>
                          this.changeState('email', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                      />
                      <InputOutlined
                        id="regpassword"
                        autocomplete="current-password"
                        type="password"
                        label="Password"
                        placeholder="Password must be at least 4 characters"
                        value={password}
                        onChange={e =>
                          this.changeState('password', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                      />
                      <InputOutlined
                        id="regconfpassword"
                        autocomplete="current-password"
                        type="password"
                        label="Password Confirmation"
                        placeholder="Input password once again"
                        value={confirmpassword}
                        onChange={e =>
                          this.changeState('confirmpassword', e.target.value)
                        }
                        margin="0 0 15px"
                        padding="10px 12px"
                      />
                      <Select
                        label="Gender"
                        value={gender}
                        option={genderlist}
                        optionKey="name"
                        onChange={this.changeState}
                        stateKey="gender"
                        margin="0 0 30px"
                        padding="10px 12px"
                      />
                      <ErrorText center margin="0 0 15px">
                        {error}
                      </ErrorText>
                      <Button
                        color="blue"
                        padding="10px 20px"
                        block
                        disabled={loading}
                      >
                        {loading ? <Spinner /> : 'Register'}
                      </Button>
                    </form>
                  </Col>
                </Row>
              </Container>
            </Flex>
          </FlexCell>

          <FlexCell>
            <Footer location={this.props.location} />
            <Copyright />
          </FlexCell>
        </PageWrapper>
        <ModalSuccess
          open={modal}
          message={success}
          close={() => this.changeState('modal', false)}
        />
      </Fragment>
    )
  }
}

export default withContext(Register)
