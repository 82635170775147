import styled from '@emotion/styled'
import { blue, white, gaegu } from '../../assets/styles/styles'

const Wrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  padding: 10px 15px;
  border-radius: 15px;
  font-family: ${gaegu};
  font-weight: bold;
  font-size: 18px;
  background-color: ${blue};
  color: ${white};
  cursor: pointer;
  z-index: 10;
`

export { Wrapper }
