import styled from '@emotion/styled'
import { blue, white } from '../../assets/styles/styles'

const BannerBulletContainer = styled.div`
  position: absolute;
  bottom: 5px;
  ul {
    margin: 0;
    li {
      margin: 0;
    }
  }
`

const BannerBullet = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid ${white};
  z-index: 1;
  position: relative;
  background-color: ${props => (props.active ? white : 'transparent')};
`

const BlogStyled = styled.div`
  // border-radius: 5px;
  overflow: hidden;
  margin: 0 15px;
`

const BlogFooter = styled.div`
  background-color: ${blue};
  color: ${white};
  padding: 10px 15px;
  text-align: center;
  margin-top: -5px;
`

const Promo = styled.a`
  width: 100%;
  background-color: #f27b3a;
  display: block;
  padding: 3px 0;
  text-align: center;
  color: ${white};
  font-weight: bold;
  &:hover {
    text-decoration: none;
    color: #777;
  }
`

export { BannerBulletContainer, BannerBullet, BlogStyled, BlogFooter, Promo }
