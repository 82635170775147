import React, { Fragment } from 'react'
import { Wrapper } from './styles'
import { Image } from '../../assets/styles/components'
import ModalHelp from './ModalHelp'

class Help extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
  }
  render() {
    return (
      <Fragment>
        <Wrapper onClick={() => this.setState({ modal: true })}>
          <Image
            src={require('../../assets/images/icon-cs.png')}
            width="25px"
            height="25px"
            margin="0 10px 0 0"
          />
          Need Help?
        </Wrapper>
        <ModalHelp
          open={this.state.modal}
          close={() => this.setState({ modal: false })}
        />
      </Fragment>
    )
  }
}

export default Help
